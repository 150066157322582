<script setup lang="ts">
import { useCreateBillingCharge } from '@/composables/billing/invoices'
import { toRef } from 'vue'
import { z } from 'zod'
import InputText from '@/components/input-text.vue'
import GridContainer from '@/components/grid-container.vue'
import GridItem from '@/components/grid-item.vue'
import InputLabel from '@/components/input-label.vue'
import InputNumber from '@/components/input-number.vue'
import { roundToFractionalCurrencyPrecision } from '@/helpers'
import useNotifications from '@/composables/notifications'
import { formatToMainCurrency } from '@/helpers/formatting'
import useLoader from '@/composables/loader'
import ModalVariantForm from '@/components/modal/modal-variant-form.vue'

const props = defineProps<{ assetCode: number }>()
const emit = defineEmits<{ close: [] }>()

const { addSuccess, addError } = useNotifications()
const { wrap: wrapWithLoader } = useLoader()
const { mutateAsync: createBillingCharge } = useCreateBillingCharge(toRef(props, 'assetCode'))

const validationSchema = z.object({
  description: z.string().min(1),
  rateInMainCurrency: z.number().gt(0),
})

function performSave(values: z.infer<typeof validationSchema>) {
  wrapWithLoader(
    () => createBillingCharge({
      description: values.description,
      rateInFractionalCurrency: roundToFractionalCurrencyPrecision(values.rateInMainCurrency * 100),
      quantity: 1,
    })
  ).then(
    () => {
      addSuccess({
        title: 'Recurring charge created successfully.',
        allowHtml: true,
        message: `Your new recurring charge <b>${values.description}</b> for <b>${formatToMainCurrency(values.rateInMainCurrency)}</b> has been created successfully, and will now be present in invoices generated using this template.`
      })

      emit('close')
    },
    () => addError({
      title: 'Recurring charge not created.',
      message: 'We were unable to create your new recurring charge. Please try again.'
    })
  )
}
</script>

<template>
  <ModalVariantForm :width="393" title="New Charge" @cancel="$emit('close')" @submit="performSave" :schema="validationSchema">
    <GridContainer variant="inputs">

      <GridItem>
        <InputLabel class="text-nowrap">Charge Description</InputLabel>
      </GridItem>
      <GridItem>
        <InputText name="description" required error-variant="tooltip" />
      </GridItem>

      <GridItem>
        <InputLabel>Rate</InputLabel>
      </GridItem>
      <GridItem>
        <InputNumber name="rateInMainCurrency" required error-variant="tooltip">
          <template #prefix><span class="text-muted">R</span></template>
        </InputNumber>
      </GridItem>

    </GridContainer>
  </ModalVariantForm>
</template>

<style module lang="scss">

</style>
